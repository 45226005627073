import { useQuery } from '@tanstack/react-query';
import { customFetch } from '~/utils/customFetch';
import { ErrorObjectString } from '~/utils/typescriptHelpers';

export type PartnerData = {
  label: string;
  id: string;
};

type ResponseData = PartnerData[] | ErrorObjectString;

async function getPartners(query: string | null = null) {
  const requestOptions = query
    ? {
        params: {
          q: query,
        },
      }
    : {};
  const { data } = await customFetch.get<ResponseData>(`/partnersJSON.pl`, requestOptions);
  if ('error' in data) {
    throw new Error(data.error);
  }
  return data.map((partner) => ({
    label: partner.label,
    id: Number(partner.id),
  }));
}

export function getPartnersQueryOptions() {
  return {
    queryKey: ['partners'],
    queryFn: async () => getPartners(),
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 5, // 5 minutes
  };
}

const usePartnerData = () => {
  return useQuery(getPartnersQueryOptions());
};

export default usePartnerData;
